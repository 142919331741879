import React from "react";
import { Route } from "react-router-dom";
import TermsAndConditions from "../Common/Terms&Condition";
import ContactUs from "../Common/ContactUs";
import Privacy from "../Common/Privacy";

const publicRoutes = [
  <Route path="/terms" element={<TermsAndConditions />} />,
  <Route path="/ContactUs" element={<ContactUs />} />,
  <Route path="/privacy" element={<Privacy />} />,
];

export default publicRoutes;
