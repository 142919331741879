import React, { useEffect, useState } from "react";
import CoursesListing from "./Components/StudentCoursesListing";
import PracticeLayout from "./Layout";
import {
  useGetAllCoursesByClusterQuery,
  useGetStudentByEmailIdQuery,
  useGetCampusQuery,
} from "../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import { Spinner, Alert } from "flowbite-react";
import StudentLayout from "../Student/Layout/index.jsx";

function Practice() {
  const [courseData, setCourseData] = useState([]);
  const { clusterId } = useParams();
  const email = JSON.parse(localStorage.getItem("user"))?.email;

  const {
    data: studentData,
    loading: studentLoading,
    error: studentError,
  } = useGetStudentByEmailIdQuery({
    variables: { email },
  });

  const campusId = studentData?.getStudentByEmailId?.campusId;
  const {
    data: campusData,
    loading: campusLoading,
    error: campusError,
  } = useGetCampusQuery({
    variables: { id: campusId },
    skip: !campusId,
  });

  const campusName = campusData?.campus?.name || "";
  const {
    data: coursesData,
    loading: coursesLoading,
    error: coursesError,
  } = useGetAllCoursesByClusterQuery({
    variables: { clusterId, campusName },
    skip: !campusName,
  });

  useEffect(() => {
    if (coursesData?.getAllCoursesByCluster) {
      setCourseData(coursesData.getAllCoursesByCluster);
    }
  }, [coursesData]);

  if (studentLoading || campusLoading || coursesLoading) {
    return (
      <PracticeLayout>
          <div className="flex justify-center items-center h-screen">
            <Spinner size="lg" />
          </div>
      </PracticeLayout>
    );
  }

  if (studentError || campusError || coursesError) {
    return (
      <PracticeLayout>
        <div className="flex items-center justify-center min-h-screen">
          <Alert color="failure">
            <span>Error loading data. Please try again later.</span>
          </Alert>
        </div>
      </PracticeLayout>
    );
  }

  return (
    <StudentLayout>
      <div className="ml-14 mt-10 max-md:ml-0">
        <div className="text-center space-y-4 max-md:p-8">
          <h1 className="text-4xl font-bold max-md:text-3xl">
            Courses Enrolled
          </h1>
          <p className="text-lg text-gray-700">
            Explore the courses you are enrolled in and start learning!
          </p>
        </div>
        <CoursesListing courseData={courseData} clusterId={clusterId} />
      </div>
    </StudentLayout>
  );
}

export default Practice;
