import React, { useEffect, useRef, useState } from "react";
import {
  useGetAssignmentQuery,
  useGetAssignmentQuestionQuery,
} from "../../../../../graphql/generated.tsx";
import { useParams } from "react-router-dom";
import TestLayout from "../../../../Assignments/Layout/TestLayout";
import BackButton from "../../../../Assignments/Components/BackButton";
import CustomInput from "../../../../Practice/Components/CustomInput";
import CompilerQuestionCard from "../../../../Assignments/Components/CompilerQuestionCard.jsx";
import Compiler from "../../../../Practice/Components/Compiler.jsx";

const PreviewAssignmentQuestion = () => {
  const { questionId, assignmentId } = useParams();
  const [code, setCode] = useState("");
  const [codeApplied, setCodeApplied] = useState(false);
  const [input, setInput] = useState("");
  const [question, setQuestion] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const errorRef = useRef(null);
  const [isFullScreen, setFullScreen] = useState(true);
  const { data, loading } = useGetAssignmentQuestionQuery({
    variables: { id: questionId },
  });

  const { data: Assignment, refetch } = useGetAssignmentQuery({
    variables: { id: assignmentId },
  });
  const [language, setLanguage] = useState();
  useEffect(() => {
    refetch();
    if (Assignment?.getAssignment) {
      const defaultLang = Assignment?.getAssignment?.languages[0];
      setLanguage(defaultLang || "C");
    }
  }, [Assignment]);

  const extractFunctionCode = (code, language, targetFunctionName) => {
    let functionRegex;

    switch (language) {
      case "python":
        functionRegex = new RegExp(
          `def\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*:[\\s\\S]*?(?=\\n\\S|$)`,
          "g"
        );
        break;
      case "java":
        functionRegex = new RegExp(
          `public\\s+static\\s+\\w+\\s+${targetFunctionName}\\s*\\([^)]*\\)\\s*{[^}]*}`,
          "g"
        );
        break;
      case "cpp":
      case "c":
        functionRegex = new RegExp(
          `\\w+(\\s*\\*\\s*|\\s+)${targetFunctionName}\\s*\\([^)]*\\)\\s*{([\\s\\S]*?)}(?![^}]*})`,
          "g"
        );
        break;
      default:
        return "";
    }

    const match = code?.match(functionRegex);
    return match ? match[0] : "";
  };

  useEffect(() => {
    if (data) {
      setQuestion(data.getAssignmentQuestion);
      const langKey = language?.toLowerCase();

      if (
        data?.getAssignmentQuestion?.showFunctionOnly &&
        data?.getAssignmentQuestion?.predefinedCode[langKey]
      ) {
        const targetFunctionCode = extractFunctionCode(
          data?.getAssignmentQuestion?.predefinedCode[langKey],
          langKey,
          data?.getAssignmentQuestion.targetFunctionName
        );
        setCode(targetFunctionCode);
      } else if (data?.getAssignmentQuestion?.predefinedCode[langKey]) {
        setCode(data.getAssignmentQuestion.predefinedCode[langKey]);
      } else {
        setCode("//Type your code here");
      }
    }
  }, [data, language]);

  const handleCodeChange = (newValue) => {
    setCode(newValue);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setInput(""); // Reset input when checkbox is toggled
  };
  const setToDefault = () => {
    const langKey = language?.toLowerCase();
    if (
      data?.getAssignmentQuestion.showFunctionOnly &&
      data?.getAssignmentQuestion.predefinedCode[langKey]
    ) {
      const targetFunctionCode = extractFunctionCode(
        data.getAssignmentQuestion.predefinedCode[langKey],
        langKey,
        data.getAssignmentQuestion.targetFunctionName
      );
      setCode(targetFunctionCode);
    } else if (data?.getAssignmentQuestion.predefinedCode[langKey]) {
      setCode(data.getAssignmentQuestion.predefinedCode[langKey]);
    }
  };

  return (
    <TestLayout assignmentId={assignmentId} disableSubmit={true} previewMode>
      <div className="pt-8 bg-slate-100">
        <div className={"flex max-md:flex-col"}>
          <div className={"lg:w-1/2 pr-4"}>
            <CompilerQuestionCard
              question={question}
              loading={loading}
              submitInput={() => {}}
              onApply={setCode}
            />
          </div>
          <div className={"lg:w-1/2 pl-4"}>
            <Compiler
              question_id={questionId}
              language={language}
              code={code}
              setToDefault={setToDefault}
              setLanguage={setLanguage}
              handleCodeChange={handleCodeChange}
              errorRef={errorRef}
              CourseData={Assignment?.getAssignment}
              isChecked={isChecked}
              setCodeApplied={setCodeApplied}
              previewMode
            />
            <CustomInput
              isChecked={isChecked}
              handleCheckboxChange={handleCheckboxChange}
              setInput={setInput}
              input={input}
            />
          </div>
        </div>
      </div>
    </TestLayout>
  );
};

export default PreviewAssignmentQuestion;
