import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getAuth, onIdTokenChanged } from "firebase/auth"; 

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const refreshToken = async () => {
  const auth = getAuth(); 
  const user = auth.currentUser;
  if (user) {
    const token = await user.getIdToken(true); 
    localStorage.setItem("idtoken", token); 
  }
};

setInterval(() => {
  refreshToken();
}, 55 * 60 * 1000); 

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("idtoken");
  const uid = JSON.parse(localStorage.getItem("user"))?.uid;

  return {
    headers: {
      ...headers,
      clientid: uid || "",
      authorization: token ? `${token}` : "",
    },
  };
});

const auth = getAuth();
onIdTokenChanged(auth, async (user) => {
  if (user) {
    const token = await user.getIdToken();
    localStorage.setItem("idtoken", token);
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
